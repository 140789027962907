.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.main-body {
  background-color: #141414;
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#bracket {
  padding-top: 80px;
}

@media only screen and (max-width: 600px) {
  .App-header img {
    max-width: 350px;
  }
}

footer.main-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: white;
  text-align: center;
}

.scroller__inner {
  background: #fff;
  padding-block: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
}

.scroller__inner img {
  margin-right: 30px;
}

.scroller[data-animated="true"] {
  overflow: hidden;
  -webkit-mask: linear-gradient(90deg,
      transparent,
      white 20%,
      white 80%,
      transparent);
  mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
}

.scroller[data-animated="true"] .scroller__inner {
  width: max-content;
  flex-wrap: nowrap;
  animation: scroll var(--_animation-duration, 40s) var(--_animation-direction, forwards) linear infinite;
}

.scroller[data-direction="right"] {
  --_animation-direction: reverse;
}

.scroller[data-direction="left"] {
  --_animation-direction: forwards;
}

.scroller[data-speed="fast"] {
  --_animation-duration: 20s;
}

.scroller[data-speed="slow"] {
  --_animation-duration: 60s;
}

@keyframes scroll {
  to {
      transform: translate(calc(-50% - 0.5rem));
  }
}

@media screen and (min-width:768px) {
  img.poster-image {
      max-height: 1000px;
      padding: 50px;
  }
}

@media screen and (max-width:767px) {
  img.poster-image {
      max-height: 500px;
      max-width: 80%;
  }
}

header.App-header {
  border-bottom: solid 1px white;
}

#history {
  margin-bottom: 150px;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

li {
  float: left;
}

li a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

/* Change the link color to #111 (black) on hover */
li a:hover {
  background-color: #111;
}

.nav-container {
  display: flex;
  justify-content: center;
}

@media screen and (min-width:768px) {
  img.champ-image {
      max-height: 1000px;
      padding: 20px;
  }
}

@media screen and (max-width:767px) {
  img.champ-image {
      max-height: 500px;
      max-width: 80%;
  }
}

a {
  color: white;
}

span.brackettext {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}